"use client";

import { useTranslations } from "next-intl";

import { LoggedUser } from "@/@codegen/supergraph";

import { useUserRepository } from "@/repositories/userRepository";
import { toast } from "react-hot-toast";
import { useLoggedUser } from "@/state/user/useLoggedUser";

export function useUserService() {
  const userRepository = useUserRepository();
  const { setLoggedUser } = useLoggedUser();

  function resendVerificationEmailForLoggedUser() {
    const t = useTranslations();

    return userRepository
      .resendVerificationEmailForLoggedUser()
      .then(() => {
        toast.success(t("verification.emailSent"));
      })
      .catch(() => {
        toast.error(t("verification.emailError"));
      });
  }

  async function fetchLoggedUser() {
    const loggedUser = (await userRepository.fetchLoggedUser()) as LoggedUser;
    if (!loggedUser) {
      throw new Error("User not found");
    }
    setLoggedUser(loggedUser);
    return loggedUser;
  }

  return { resendVerificationEmailForLoggedUser, fetchLoggedUser };
}
