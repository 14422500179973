"use client";

import { ROUTES } from "@/routes";

export function findClosestRoute(pathname: string): string {
  const pathParts = pathname.split("?")[0].split("/").filter(Boolean);
  let closestRoute = "";

  Object.entries({ ...ROUTES.private, ...ROUTES.public }).forEach(
    ([_route, path]) => {
      const routeParts = path.split("/").slice(1);
      if (routeParts.length <= pathParts.length) {
        const match = routeParts.every(
          (part, index) =>
            (part === "" && Boolean(pathParts[index])) ||
            part === pathParts[index],
        );
        if (match && path.length > closestRoute.length) {
          closestRoute = path;
        }
      }
    },
  );
  if (!closestRoute) return "";

  let finalRoute = "";
  Object.keys(ROUTES.public).forEach((route) => {
    if (ROUTES.public[route as keyof typeof ROUTES.public] === closestRoute) {
      finalRoute = `ROUTES.public.${route}`;
      return;
    }
  });
  if (finalRoute) return finalRoute;
  Object.keys(ROUTES.private).forEach((route) => {
    if (ROUTES.private[route as keyof typeof ROUTES.private] === closestRoute) {
      finalRoute = `ROUTES.private.${route}`;
      return;
    }
  });
  return finalRoute;
}
