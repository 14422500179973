"use client";

/**
 * This is used to store the previous URL that the user was on
 * It's used by the BackButton component
 * Purpose is to preserve the search params when the user navigates back
 */
export const PREVIOUS_URL_STORAGE_KEY = "previous-url";

export default function useSessionStorage() {
  let sessionStorage: Storage;

  if (typeof window !== "undefined") {
    sessionStorage = window.sessionStorage;
  }
  function setItem(key: string, value: any) {
    sessionStorage.setItem(key, JSON.stringify(value));
  }

  function getItem(key: string) {
    try {
      const item = sessionStorage.getItem(key);
      return item ? JSON.parse(item) : null;
    } catch {
      return null;
    }
  }

  function hasItem(key: string | RegExp) {
    return Object.keys(sessionStorage).some((k) => {
      if (key instanceof RegExp) {
        return key.test(k);
      }
      return k === key;
    });
  }

  return {
    hasItem,
    setItem,
    getItem,
  };
}
