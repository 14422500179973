import React from "react";

import type {
  Event,
  Payload,
  IdentifyOptions,
} from "@texturehq/analytics-client";

import { create } from "zustand";

type AnalyticsStore = {
  analytics: any | null;
  setAnalytics: (analytics: any) => void;
};

const useAnalyticsStore = create<AnalyticsStore>((set) => ({
  analytics: null,
  setAnalytics: (analytics) => set({ analytics }),
}));

type AnalyticsEvent = "loadAnalytics" | "track" | "identify";
type AnalyticsPayload = {
  event: AnalyticsEvent;
  payload: any;
};

const useAnalytics = () => {
  const { analytics, setAnalytics } = useAnalyticsStore();

  /**
   * It could happen that an event takes place before the analytics client is initialized.
   * In that case, we will store the event in a stack and send it once the analytics client is initialized.
   */
  const [stack, setStack] = React.useState<AnalyticsPayload[]>([]);

  const fireStack = () => {
    stack.forEach((event: AnalyticsPayload) => {
      if (event.event === "loadAnalytics") {
        analytics.load(event.payload);
      } else if (event.event === "track") {
        analytics.track(event.payload.event, event.payload.payload);
      } else if (event.event === "identify") {
        analytics.identify(event.payload);
      }
    });
    setStack([]);
  };

  React.useEffect(() => {
    if (analytics) {
      fireStack();
    }
  }, [analytics]);

  const initialize = async () => {
    const analytics = await import("@texturehq/analytics-client");
    setAnalytics(analytics);
    return analytics;
  };

  const loadAnalytics = async (writeKey: string, dataPlaneUrl: string) => {
    const analytics = await initialize();
    await analytics.load({ writeKey, dataPlaneUrl });
  };

  const track = (event: Event, payload: Payload) => {
    if (!analytics) {
      setStack((prev) => [
        ...prev,
        { event: "track", payload: { event, payload } },
      ]);
      return;
    }
    analytics.track(event, payload);
  };

  const identify = (user: IdentifyOptions) => {
    if (!analytics) {
      setStack((prev) => [...prev, { event: "identify", payload: user }]);
      return;
    }
    analytics.identify(user);
  };

  return {
    loadAnalytics,
    track,
    identify,
  };
};

export default useAnalytics;
