import {
  LoggedUserDocument,
  PreferredTemperatureUnit,
  SignUpUserInput,
  AuthenticateByEmailPasswordInput,
  useChangePasswordMutation,
  useLoggedUserAcceptedInvitationsLazyQuery,
  useLoggedUserLazyQuery,
  useLoggedUserPendingInvitationsLazyQuery,
  useResendVerificationEmailMutation,
  useSaveUserOnboardingDataMutation,
  useSignUpMutation,
  useUpdateUserProfileMutation as gqlUseUpdateProfileMutation,
  useAuthenticateByEmailPasswordMutation,
  useRefreshAuthenticationSessionMutation,
  LoggedUser,
} from "@/@codegen/supergraph";
import {
  processGraphQLOperationErrorsAndThrow,
  processSingleOperationGraphQLResponse,
} from "@/utils";
import { notEmpty } from "@9yco/utils.utils";
import { useLoggedUser } from "@/state/user/useLoggedUser";

export function useUserRepository() {
  const { loggedUser } = useLoggedUser();

  const [fetchLoggedUserLazy] = useLoggedUserLazyQuery({
    fetchPolicy: "no-cache",
  });

  const [loggedUserPendingInvitationsQuery] =
    useLoggedUserPendingInvitationsLazyQuery();

  const [loggedUserAcceptedInvitationsQuery] =
    useLoggedUserAcceptedInvitationsLazyQuery();

  const updateUserProfileMutation = useUpdateUserProfileMutation();

  const [resendVerificationEmailMutation] =
    useResendVerificationEmailMutation();

  const [saveUserOnboardingDataMutation] = useSaveUserOnboardingDataMutation();

  const [signUpMutation] = useSignUpMutation();

  const [changePasswordMutation] = useChangePasswordMutation();

  const [authenticateByEmailPasswordMutation] =
    useAuthenticateByEmailPasswordMutation();

  const [refreshAuthenticationSessionMutation] =
    useRefreshAuthenticationSessionMutation();

  function updatePreferredTemperatureUnit({
    user,
    unit,
  }: {
    user: LoggedUser;
    unit: PreferredTemperatureUnit;
  }) {
    return updateUserProfileMutation({
      variables: { userId: user.id, input: { preferredTemperatureUnit: unit } },
    })
      .then(processSingleOperationGraphQLResponse("updateUserProfile"))
      .catch(processGraphQLOperationErrorsAndThrow);
  }

  function saveUserOnboardingData(input: SaveUserOnboardingDataInput) {
    return saveUserOnboardingDataMutation({
      variables: {
        id: input.userId,
        userId: input.userId,
        updateUserInput: {
          firstName: input.firstName,
          lastName: input.lastName,
        },
        updateUserProfileInput: {
          marketingRole: input.role === "Other" ? input.otherRole : input.role,
          preferredTimeZone: input.timeZone,
          preferredTemperatureUnit: input.unit,
        },
      },
    })
      .then((result) => {
        if (!result.errors) {
          // Add logging
          throw new Error("Something went wrong");
        }

        return result?.data?.updateUser;
      })
      .catch(processGraphQLOperationErrorsAndThrow);
  }

  // TODO: if this user is logged in, we don't need to send an email, this should be in the header and fetchable from BE
  function resendVerificationEmailForLoggedUser() {
    return resendVerificationEmailMutation({
      variables: { input: { email: loggedUser?.email?.email } },
    })
      .then(processSingleOperationGraphQLResponse("resendVerificationEmail"))
      .catch(processGraphQLOperationErrorsAndThrow);
  }

  function fetchLoggedUser() {
    return fetchLoggedUserLazy()
      .then(processSingleOperationGraphQLResponse("me"))
      .catch(processGraphQLOperationErrorsAndThrow)
      .catch((error) => {
        // I know :| we need error codes propagated to the UI
        if (error?.message?.includes("JWT")) {
          return null;
        }

        throw error;
      });
  }

  function refreshAuthenticationSession(
    refreshToken: string,
    organizationId?: string,
  ) {
    return refreshAuthenticationSessionMutation({
      variables: { input: { organizationId } },
      context: {
        headers: {
          "X-Texture-Refresh-Token": refreshToken,
        },
      },
    })
      .then(
        processSingleOperationGraphQLResponse("refreshAuthenticationSession"),
      )
      .catch(processGraphQLOperationErrorsAndThrow);
  }

  function signIn(input: AuthenticateByEmailPasswordInput) {
    return authenticateByEmailPasswordMutation({
      variables: {
        input,
      },
    })
      .then(
        processSingleOperationGraphQLResponse("authenticateByEmailPassword"),
      )
      .catch(processGraphQLOperationErrorsAndThrow);
  }

  function signUp(input: SignUpUserInput) {
    return signUpMutation({
      variables: {
        input,
      },
    })
      .then(processSingleOperationGraphQLResponse("signUp"))
      .catch(processGraphQLOperationErrorsAndThrow);
  }

  function changePassword(email: string) {
    return changePasswordMutation({
      variables: {
        input: {
          email,
        },
      },
    })
      .then(processSingleOperationGraphQLResponse("changePassword"))
      .catch(processGraphQLOperationErrorsAndThrow);
  }

  // TODO: this belongs somewhere in the user state or in the user hooks
  function doesLoggedUserHavePendingInvitations() {
    return loggedUserPendingInvitationsQuery()
      .then(processSingleOperationGraphQLResponse("me"))
      .then((loggedUser) => notEmpty(loggedUser?.invitations))
      .catch(processGraphQLOperationErrorsAndThrow);
  }

  function isLoggedUserSignedUpToOrganizationFromInvitation(
    organizationId: string,
  ) {
    return loggedUserAcceptedInvitationsQuery({ variables: { organizationId } })
      .then(processSingleOperationGraphQLResponse("me"))
      .then((loggedUser) => notEmpty(loggedUser?.invitations))
      .catch(processGraphQLOperationErrorsAndThrow);
  }

  return {
    resendVerificationEmailForLoggedUser,
    updatePreferredTemperatureUnit,
    doesLoggedUserHavePendingInvitations,
    isLoggedUserSignedUpToOrganizationFromInvitation,
    saveUserOnboardingData,
    fetchLoggedUser,
    changePassword,
    signIn,
    signUp,
    refreshAuthenticationSession,
  };
}

function useUpdateUserProfileMutation() {
  const [mutation] = gqlUseUpdateProfileMutation({
    awaitRefetchQueries: true,
    refetchQueries: [{ query: LoggedUserDocument }],
  });

  return mutation;
}

interface SaveUserOnboardingDataInput {
  userId: string;
  firstName: string;
  lastName: string;
  role: string;
  otherRole?: string;
  timeZone: string;
  unit: PreferredTemperatureUnit;
}
