"use client";

const TOKEN_KEY = "dashboard-token";
const REFRESH_TOKEN_KEY = "dashboard-refresh-token";

export default function useLocalStorage() {
  let localStorage: Storage;

  if (typeof window !== "undefined") {
    localStorage = window.localStorage;
  }
  function setItem(key: string, value: any) {
    localStorage.setItem(key, JSON.stringify(value));
  }

  function getItem(key: string) {
    try {
      const item = localStorage.getItem(key);
      return item ? JSON.parse(item) : null;
    } catch {
      return null;
    }
  }

  function hasItem(key: string | RegExp) {
    return Object.keys(localStorage).some((k) => {
      if (key instanceof RegExp) {
        return key.test(k);
      }
      return k === key;
    });
  }

  function getToken(key = TOKEN_KEY): string | null {
    try {
      return localStorage.getItem(key);
    } catch {
      return null;
    }
  }

  function setToken(token: string, key = TOKEN_KEY) {
    if (token === "undefined") {
      return;
    }

    try {
      localStorage.setItem(key, token);
    } catch (error) {
      console.error("Error setting token in window.localStorage");
      console.error(error);
    }
  }

  function getRefreshToken(): string | null {
    try {
      return localStorage.getItem(REFRESH_TOKEN_KEY);
    } catch (e) {
      return null;
    }
  }

  function setRefreshToken(token: string) {
    if (token === "undefined") {
      return;
    }

    try {
      localStorage.setItem(REFRESH_TOKEN_KEY, token);
    } catch (error) {
      console.error("Error setting token in window.localStorage");
      console.error(error);
    }
  }

  function removeToken(key = TOKEN_KEY) {
    try {
      localStorage.removeItem(key);
    } catch (error) {
      console.error("Error removing token from window.localStorage");
      console.error(error);
      throw error;
    }
  }
  function removeRefreshToken() {
    try {
      localStorage.removeItem(REFRESH_TOKEN_KEY);
    } catch (error) {
      console.error("Error removing token from window.localStorage");
      console.error(error);
      throw error;
    }
  }

  function isThereAnyLocalData() {
    try {
      return Object.keys(localStorage).length > 0;
    } catch {
      return false;
    }
  }

  return {
    hasItem,
    setItem,
    getItem,
    setToken,
    getToken,
    removeToken,
    setRefreshToken,
    getRefreshToken,
    removeRefreshToken,
    isThereAnyLocalData,
  };
}
