import { gql } from "@apollo/client";
import * as Apollo from "@apollo/client";
export type Maybe<T> = T | null;
export type InputMaybe<T> = Maybe<T>;
export type Exact<T extends { [key: string]: unknown }> = {
  [K in keyof T]: T[K];
};
export type MakeOptional<T, K extends keyof T> = Omit<T, K> & {
  [SubKey in K]?: Maybe<T[SubKey]>;
};
export type MakeMaybe<T, K extends keyof T> = Omit<T, K> & {
  [SubKey in K]: Maybe<T[SubKey]>;
};
export type MakeEmpty<
  T extends { [key: string]: unknown },
  K extends keyof T,
> = { [_ in K]?: never };
export type Incremental<T> =
  | T
  | {
      [P in keyof T]?: P extends " $fragmentName" | "__typename" ? T[P] : never;
    };
const defaultOptions = {} as const;
/** All built-in and custom scalars, mapped to their actual values */
export type Scalars = {
  ID: { input: string; output: string };
  String: { input: string; output: string };
  Boolean: { input: boolean; output: boolean };
  Int: { input: number; output: number };
  Float: { input: number; output: number };
  BigInt: { input: any; output: any };
  Date: { input: any; output: any };
  DateTime: { input: any; output: any };
  JSON: { input: any; output: any };
  JSONObject: { input: any; output: any };
  Time: { input: any; output: any };
};

export type ApiKey = {
  __typename?: "ApiKey";
  createdAt: Scalars["DateTime"]["output"];
  id: Scalars["ID"]["output"];
  key: Scalars["String"]["output"];
  name: Scalars["String"]["output"];
  revoked: Scalars["Boolean"]["output"];
  type: ApiKeyType;
  updatedAt: Scalars["DateTime"]["output"];
  workspace: Workspace;
  workspaceId: Scalars["String"]["output"];
};

export enum ApiKeyRole {
  Admin = "ADMIN",
  Viewer = "VIEWER",
}

export enum ApiKeyType {
  Connect = "CONNECT",
  Public = "PUBLIC",
  Secret = "SECRET",
  Server = "SERVER",
}

export type ApiKeysFilter = {
  workspaceId: Scalars["ID"]["input"];
};

export type ApiKeysInput = {
  filter: ApiKeysFilter;
  pagination?: InputMaybe<ListPagination>;
  search?: InputMaybe<SearchInput>;
  sort?: InputMaybe<Scalars["String"]["input"]>;
};

export type ApiKeysPayload = {
  __typename?: "ApiKeysPayload";
  data?: Maybe<Array<ApiKey>>;
  meta?: Maybe<ListPayloadMeta>;
};

export type ConnectLink = {
  __typename?: "ConnectLink";
  linkToken: Scalars["String"]["output"];
  linkUrl: Scalars["String"]["output"];
};

export type ContactFormSubmission = {
  __typename?: "ContactFormSubmission";
  createdAt: Scalars["DateTime"]["output"];
  email?: Maybe<Scalars["String"]["output"]>;
  id: Scalars["String"]["output"];
  message?: Maybe<Scalars["String"]["output"]>;
  name?: Maybe<Scalars["String"]["output"]>;
};

export type CreateApiKeyInput = {
  name: Scalars["String"]["input"];
  role?: ApiKeyRole;
  type?: InputMaybe<ApiKeyType>;
  workspaceId: Scalars["String"]["input"];
};

export type CreateConnectLinkInput = {
  apiKey: Scalars["String"]["input"];
  clientName?: InputMaybe<Scalars["String"]["input"]>;
  email?: InputMaybe<Scalars["String"]["input"]>;
  phone?: InputMaybe<Scalars["String"]["input"]>;
  redirectUrl: Scalars["String"]["input"];
  referenceId: Scalars["String"]["input"];
  tags?: InputMaybe<Array<InputMaybe<Scalars["String"]["input"]>>>;
};

export type CreateContactFormSubmissionInput = {
  email: Scalars["String"]["input"];
  message: Scalars["String"]["input"];
  name: Scalars["String"]["input"];
};

export type CreateDestinationInput = {
  destinationType: Scalars["String"]["input"];
  deviceType: Scalars["String"]["input"];
  email?: InputMaybe<Scalars["String"]["input"]>;
  headers?: InputMaybe<Scalars["JSON"]["input"]>;
  includeWeather?: InputMaybe<Scalars["Boolean"]["input"]>;
  label: Scalars["String"]["input"];
  phoneNumber?: InputMaybe<Scalars["String"]["input"]>;
  secret?: InputMaybe<Scalars["String"]["input"]>;
  url?: InputMaybe<Scalars["String"]["input"]>;
  workspaceId: Scalars["String"]["input"];
};

export type CreateEmailInput = {
  email: Scalars["String"]["input"];
  token: Scalars["String"]["input"];
  userId: Scalars["String"]["input"];
  verified: Scalars["Boolean"]["input"];
};

export type CreateMembershipInput = {
  organizationId: Scalars["String"]["input"];
  role: Role;
  userId: Scalars["String"]["input"];
};

export type CreateOrganizationInput = {
  domain?: InputMaybe<Scalars["String"]["input"]>;
  logo?: InputMaybe<Scalars["String"]["input"]>;
  name: Scalars["String"]["input"];
};

export type CreateOrganizationInvitationInput = {
  email: Scalars["String"]["input"];
  organization: Scalars["String"]["input"];
  role: Role;
};

export type CreateUserInput = {
  emails?: InputMaybe<Array<Scalars["String"]["input"]>>;
  firstName?: InputMaybe<Scalars["String"]["input"]>;
  lastName?: InputMaybe<Scalars["String"]["input"]>;
  profilePhoto?: InputMaybe<Scalars["String"]["input"]>;
  supertokensId: Scalars["String"]["input"];
};

export type CreateWorkspaceInput = {
  name: Scalars["String"]["input"];
  organizationId: Scalars["String"]["input"];
};

export type CursorPagination = {
  after?: InputMaybe<Scalars["String"]["input"]>;
  before?: InputMaybe<Scalars["String"]["input"]>;
  first?: InputMaybe<Scalars["Int"]["input"]>;
  last?: InputMaybe<Scalars["Int"]["input"]>;
};

export type CursorPaginationInfo = {
  __typename?: "CursorPaginationInfo";
  endCursor?: Maybe<Scalars["String"]["output"]>;
  startCursor?: Maybe<Scalars["String"]["output"]>;
};

export type DeleteDestinationInput = {
  id: Scalars["ID"]["input"];
  workspaceId: Scalars["String"]["input"];
};

export type Destination = {
  __typename?: "Destination";
  createdAt: Scalars["DateTime"]["output"];
  destinationType: Scalars["String"]["output"];
  deviceType: Scalars["String"]["output"];
  email?: Maybe<Scalars["String"]["output"]>;
  headers?: Maybe<Scalars["JSON"]["output"]>;
  id: Scalars["String"]["output"];
  includeWeather?: Maybe<Scalars["Boolean"]["output"]>;
  label: Scalars["String"]["output"];
  phoneNumber?: Maybe<Scalars["String"]["output"]>;
  secret?: Maybe<Scalars["String"]["output"]>;
  updatedAt: Scalars["DateTime"]["output"];
  url?: Maybe<Scalars["String"]["output"]>;
  workspaceId: Scalars["String"]["output"];
};

export type DestinationsInput = {
  filter: DestinationsOnWorkspaceFilter;
  pagination?: InputMaybe<ListPagination>;
  search?: InputMaybe<SearchInput>;
  sort?: InputMaybe<Scalars["String"]["input"]>;
};

export type DestinationsOnWorkspaceFilter = {
  destinationType?: InputMaybe<Scalars["String"]["input"]>;
  workspaceId: Scalars["ID"]["input"];
};

export type DestinationsPayload = {
  __typename?: "DestinationsPayload";
  data?: Maybe<Array<Maybe<Destination>>>;
  meta?: Maybe<ListPayloadMeta>;
};

export type DeviceCommandsFilter = {
  id: Scalars["ID"]["input"];
  status?: InputMaybe<Array<InputMaybe<Scalars["String"]["input"]>>>;
};

export type DeviceCommandsInput = {
  filter: DeviceCommandsFilter;
  orderBy?: InputMaybe<Scalars["JSONObject"]["input"]>;
  pagination?: InputMaybe<ListPagination>;
};

export type DeviceCommandsPayload = {
  __typename?: "DeviceCommandsPayload";
  data?: Maybe<Array<Maybe<Scalars["JSONObject"]["output"]>>>;
  meta?: Maybe<ListPayloadMeta>;
};

export type DeviceFilter = {
  connected?: InputMaybe<Scalars["Boolean"]["input"]>;
  manufacturer?: InputMaybe<Scalars["String"]["input"]>;
  manufacturerDeviceId?: InputMaybe<Scalars["String"]["input"]>;
  model?: InputMaybe<Scalars["String"]["input"]>;
  referenceId?: InputMaybe<Scalars["ID"]["input"]>;
  tags?: InputMaybe<Array<Scalars["String"]["input"]>>;
  type?: InputMaybe<Scalars["String"]["input"]>;
};

export type DeviceLocation = {
  __typename?: "DeviceLocation";
  city?: Maybe<Scalars["String"]["output"]>;
  country?: Maybe<Scalars["String"]["output"]>;
  fullAddress?: Maybe<Scalars["String"]["output"]>;
  latitude?: Maybe<Scalars["Float"]["output"]>;
  longitude?: Maybe<Scalars["Float"]["output"]>;
  postalCode?: Maybe<Scalars["String"]["output"]>;
  state?: Maybe<Scalars["String"]["output"]>;
  streetOne?: Maybe<Scalars["String"]["output"]>;
  streetTwo?: Maybe<Scalars["String"]["output"]>;
};

export type DeviceManufacturer = {
  __typename?: "DeviceManufacturer";
  deviceId: Scalars["String"]["output"];
  image?: Maybe<Scalars["String"]["output"]>;
  name: Scalars["String"]["output"];
  serialNumber?: Maybe<Scalars["String"]["output"]>;
  slug: Scalars["String"]["output"];
  userId: Scalars["String"]["output"];
};

export type DeviceModel = {
  __typename?: "DeviceModel";
  image?: Maybe<Scalars["String"]["output"]>;
  name: Scalars["String"]["output"];
  slug: Scalars["String"]["output"];
};

export type DeviceOnWorkspace = {
  __typename?: "DeviceOnWorkspace";
  availableCommands: Array<Scalars["JSONObject"]["output"]>;
  connected: Scalars["Boolean"]["output"];
  connectedAt?: Maybe<Scalars["DateTime"]["output"]>;
  createdAt: Scalars["DateTime"]["output"];
  disconnectedAt?: Maybe<Scalars["DateTime"]["output"]>;
  id: Scalars["ID"]["output"];
  location?: Maybe<DeviceLocation>;
  manufacturer: DeviceManufacturer;
  model: DeviceModel;
  name: Scalars["String"]["output"];
  referenceId: Scalars["ID"]["output"];
  state?: Maybe<Scalars["JSONObject"]["output"]>;
  tags?: Maybe<Array<Scalars["String"]["output"]>>;
  type: Scalars["String"]["output"];
  updatedAt?: Maybe<Scalars["DateTime"]["output"]>;
  workspaceId: Scalars["ID"]["output"];
  year?: Maybe<Scalars["Int"]["output"]>;
};

export enum DeviceStatisticsRange {
  Last30Days = "Last30Days",
}

export type DevicesInput = {
  filter: DevicesOnWorkspaceFilter;
  orderBy?: InputMaybe<Scalars["JSONObject"]["input"]>;
  pagination?: InputMaybe<ListPagination>;
  search?: InputMaybe<SearchInput>;
};

export type DevicesMetrics = {
  __typename?: "DevicesMetrics";
  changeType?: Maybe<Scalars["String"]["output"]>;
  current: Scalars["BigInt"]["output"];
  percentageChange?: Maybe<Scalars["String"]["output"]>;
  previous: Scalars["BigInt"]["output"];
};

export type DevicesOnWorkspaceFilter = {
  connected?: InputMaybe<Array<InputMaybe<Scalars["Boolean"]["input"]>>>;
  manufacturer?: InputMaybe<Array<InputMaybe<Scalars["String"]["input"]>>>;
  manufacturerDeviceId?: InputMaybe<
    Array<InputMaybe<Scalars["String"]["input"]>>
  >;
  model?: InputMaybe<Array<InputMaybe<Scalars["String"]["input"]>>>;
  referenceId?: InputMaybe<Scalars["ID"]["input"]>;
  tags?: InputMaybe<Array<Scalars["String"]["input"]>>;
  type?: InputMaybe<Array<InputMaybe<Scalars["String"]["input"]>>>;
  workspaceId: Scalars["ID"]["input"];
};

export type DevicesOnWorkspaceStatisticsInput = {
  connected: Scalars["Boolean"]["input"];
  range: DeviceStatisticsRange;
  workspaceId: Scalars["ID"]["input"];
};

export type DevicesPayload = {
  __typename?: "DevicesPayload";
  data?: Maybe<Array<DeviceOnWorkspace>>;
  meta?: Maybe<ListPayloadMetaWithFiltersObject>;
};

export type DevicesStatistics = {
  __typename?: "DevicesStatistics";
  connectedDevices: DevicesMetrics;
  executedCommands: DevicesMetrics;
  range: DeviceStatisticsRange;
};

export type Email = {
  __typename?: "Email";
  createdAt: Scalars["DateTime"]["output"];
  domain: Scalars["String"]["output"];
  email: Scalars["String"]["output"];
  id: Scalars["String"]["output"];
  isPersonalEmailDomain: Scalars["Boolean"]["output"];
  token: Scalars["String"]["output"];
  updatedAt: Scalars["DateTime"]["output"];
  user: User;
  userId: Scalars["String"]["output"];
  verified: Scalars["Boolean"]["output"];
};

export type JoinOrganizationInput = {
  userId: Scalars["String"]["input"];
};

export type ListPagination = {
  cursor?: InputMaybe<CursorPagination>;
  offset?: InputMaybe<OffsetPagination>;
};

export type ListPayloadMeta = {
  __typename?: "ListPayloadMeta";
  count: Scalars["Int"]["output"];
  filters?: Maybe<Array<Maybe<Scalars["JSONObject"]["output"]>>>;
  hasNextPage: Scalars["Boolean"]["output"];
  hasPreviousPage: Scalars["Boolean"]["output"];
  pagination?: Maybe<ListPayloadMetaPagination>;
  totalCount: Scalars["Int"]["output"];
  totalPages: Scalars["Int"]["output"];
};

export type ListPayloadMetaFilters = {
  __typename?: "ListPayloadMetaFilters";
  current?: Maybe<Array<Maybe<Scalars["JSONObject"]["output"]>>>;
  default?: Maybe<Array<Maybe<Scalars["JSONObject"]["output"]>>>;
};

export type ListPayloadMetaPagination = {
  __typename?: "ListPayloadMetaPagination";
  cursor?: Maybe<CursorPaginationInfo>;
  offset?: Maybe<OffsetPaginationInfo>;
};

export type ListPayloadMetaWithFiltersObject = {
  __typename?: "ListPayloadMetaWithFiltersObject";
  count: Scalars["Int"]["output"];
  filters?: Maybe<ListPayloadMetaFilters>;
  hasNextPage: Scalars["Boolean"]["output"];
  hasPreviousPage: Scalars["Boolean"]["output"];
  pagination?: Maybe<ListPayloadMetaPagination>;
  totalCount: Scalars["Int"]["output"];
  totalPages: Scalars["Int"]["output"];
};

export type Membership = {
  __typename?: "Membership";
  createdAt: Scalars["DateTime"]["output"];
  id: Scalars["String"]["output"];
  isAdmin: Scalars["Boolean"]["output"];
  organization: Organization;
  organizationId: Scalars["String"]["output"];
  role: Role;
  updatedAt: Scalars["DateTime"]["output"];
  user: User;
  userId: Scalars["String"]["output"];
};

export type Mutation = {
  __typename?: "Mutation";
  createApiKey: ApiKey;
  createConnectLink: ConnectLink;
  createContactFormSubmission: ContactFormSubmission;
  createDestination: Destination;
  createEmail: Email;
  createMembership: Membership;
  createOrganization: Organization;
  createOrganizationInvitation: OrganizationInvitation;
  createUser: User;
  createWorkspace: Workspace;
  deleteDestination: Destination;
  deleteEmail: Email;
  deleteMembership: Membership;
  deleteOrganization: Organization;
  deleteOrganizationInvitation: OrganizationInvitation;
  deleteUser: User;
  deleteWorkspace: Workspace;
  joinOrganization: Organization;
  renameOrganizationWorkspace?: Maybe<Workspace>;
  requestInstallerAccess: RequestInstallerAccessResponse;
  requestManufacturerListing: RequestManufacturerListingResponse;
  revokeOrganizationInvitation?: Maybe<OrganizationInvitation>;
  updateApiKey: ApiKey;
  updateDestination: Destination;
  updateEmail: Email;
  updateMembership: Membership;
  updateOrganization: Organization;
  updateOrganizationInvitation: OrganizationInvitation;
  updateUser: User;
  updateWorkspace: Workspace;
  verifyEmail: Email;
};

export type MutationCreateApiKeyArgs = {
  input: CreateApiKeyInput;
};

export type MutationCreateConnectLinkArgs = {
  input: CreateConnectLinkInput;
};

export type MutationCreateContactFormSubmissionArgs = {
  input: CreateContactFormSubmissionInput;
};

export type MutationCreateDestinationArgs = {
  input: CreateDestinationInput;
};

export type MutationCreateEmailArgs = {
  input: CreateEmailInput;
};

export type MutationCreateMembershipArgs = {
  input: CreateMembershipInput;
};

export type MutationCreateOrganizationArgs = {
  input: CreateOrganizationInput;
};

export type MutationCreateOrganizationInvitationArgs = {
  input: CreateOrganizationInvitationInput;
};

export type MutationCreateUserArgs = {
  input: CreateUserInput;
};

export type MutationCreateWorkspaceArgs = {
  input: CreateWorkspaceInput;
};

export type MutationDeleteDestinationArgs = {
  input: DeleteDestinationInput;
};

export type MutationDeleteEmailArgs = {
  id: Scalars["String"]["input"];
};

export type MutationDeleteMembershipArgs = {
  id: Scalars["String"]["input"];
};

export type MutationDeleteOrganizationArgs = {
  id: Scalars["String"]["input"];
};

export type MutationDeleteOrganizationInvitationArgs = {
  id: Scalars["String"]["input"];
};

export type MutationDeleteUserArgs = {
  id: Scalars["String"]["input"];
};

export type MutationDeleteWorkspaceArgs = {
  id: Scalars["String"]["input"];
};

export type MutationJoinOrganizationArgs = {
  id: Scalars["String"]["input"];
  input: JoinOrganizationInput;
};

export type MutationRenameOrganizationWorkspaceArgs = {
  input: RenameOrganizationWorkspaceInput;
};

export type MutationRequestInstallerAccessArgs = {
  input: RequestInstallerAccessInput;
};

export type MutationRequestManufacturerListingArgs = {
  input: RequestManufacturerListingInput;
};

export type MutationRevokeOrganizationInvitationArgs = {
  input: RevokeOrganizationInvitationInput;
};

export type MutationUpdateApiKeyArgs = {
  id: Scalars["String"]["input"];
  input: UpdateApiKeyInput;
};

export type MutationUpdateDestinationArgs = {
  input: UpdateDestinationInput;
};

export type MutationUpdateEmailArgs = {
  id: Scalars["String"]["input"];
  input: UpdateEmailInput;
};

export type MutationUpdateMembershipArgs = {
  input: UpdateMembershipInput;
};

export type MutationUpdateOrganizationArgs = {
  id: Scalars["String"]["input"];
  input: UpdateOrganizationInput;
};

export type MutationUpdateOrganizationInvitationArgs = {
  id: Scalars["String"]["input"];
  input: UpdateOrganizationInvitationInput;
};

export type MutationUpdateUserArgs = {
  id: Scalars["String"]["input"];
  input: UpdateUserInput;
};

export type MutationUpdateWorkspaceArgs = {
  id: Scalars["String"]["input"];
  input: UpdateWorkspaceInput;
};

export type MutationVerifyEmailArgs = {
  token: Scalars["String"]["input"];
};

export type OffsetPagination = {
  page?: InputMaybe<Scalars["Int"]["input"]>;
  perPage?: InputMaybe<Scalars["Int"]["input"]>;
};

export type OffsetPaginationInfo = {
  __typename?: "OffsetPaginationInfo";
  page?: Maybe<Scalars["Int"]["output"]>;
  perPage?: Maybe<Scalars["Int"]["output"]>;
};

export type Organization = {
  __typename?: "Organization";
  createdAt: Scalars["DateTime"]["output"];
  domain?: Maybe<Scalars["String"]["output"]>;
  id: Scalars["String"]["output"];
  logo?: Maybe<Scalars["String"]["output"]>;
  members: Array<Maybe<Membership>>;
  name: Scalars["String"]["output"];
  owner: User;
  updatedAt: Scalars["DateTime"]["output"];
  url?: Maybe<Scalars["String"]["output"]>;
  workspaces: Array<Maybe<Workspace>>;
};

export type OrganizationInvitation = {
  __typename?: "OrganizationInvitation";
  createdAt: Scalars["DateTime"]["output"];
  email: Scalars["String"]["output"];
  id: Scalars["String"]["output"];
  invitedBy: User;
  organizationId: Scalars["String"]["output"];
  role: Role;
  sentAt: Scalars["DateTime"]["output"];
  status: OrganizationInvitationStatus;
  toJoin: Organization;
  updatedAt: Scalars["DateTime"]["output"];
  userId: Scalars["String"]["output"];
};

export enum OrganizationInvitationStatus {
  Accepted = "ACCEPTED",
  Pending = "PENDING",
  Revoked = "REVOKED",
}

export type OrganizationInvitationsFilter = {
  organizationId?: InputMaybe<Scalars["ID"]["input"]>;
};

export type OrganizationInvitationsInput = {
  filter: OrganizationInvitationsFilter;
  pagination?: InputMaybe<ListPagination>;
  search?: InputMaybe<SearchInput>;
  sort?: InputMaybe<Scalars["String"]["input"]>;
};

export type OrganizationInvitationsPayload = {
  __typename?: "OrganizationInvitationsPayload";
  data?: Maybe<Array<OrganizationInvitation>>;
  meta?: Maybe<ListPayloadMeta>;
};

export type OrganizationMembersFilter = {
  organizationId: Scalars["String"]["input"];
};

export type OrganizationMembersInput = {
  filter?: InputMaybe<OrganizationMembersFilter>;
  pagination?: InputMaybe<ListPagination>;
  sort?: InputMaybe<Scalars["String"]["input"]>;
};

export type OrganizationMembersPayload = {
  __typename?: "OrganizationMembersPayload";
  data?: Maybe<Array<Maybe<Membership>>>;
  meta?: Maybe<ListPayloadMeta>;
};

export type OrganizationWorkspacesFilter = {
  organizationId: Scalars["String"]["input"];
};

export type OrganizationWorkspacesInput = {
  filter?: InputMaybe<OrganizationWorkspacesFilter>;
  pagination?: InputMaybe<ListPagination>;
  sort?: InputMaybe<Scalars["String"]["input"]>;
};

export type OrganizationWorkspacesPayload = {
  __typename?: "OrganizationWorkspacesPayload";
  data?: Maybe<Array<Maybe<Workspace>>>;
  meta?: Maybe<ListPayloadMeta>;
};

/** About the Redwood queries. */
export type Query = {
  __typename?: "Query";
  _?: Maybe<Scalars["JSONObject"]["output"]>;
  apiKey?: Maybe<ApiKey>;
  apiKeys: ApiKeysPayload;
  contactFormSubmission?: Maybe<ContactFormSubmission>;
  contactFormSubmissions: Array<ContactFormSubmission>;
  destination?: Maybe<Destination>;
  destinations: DestinationsPayload;
  device?: Maybe<DeviceOnWorkspace>;
  deviceCommands: DeviceCommandsPayload;
  devices: DevicesPayload;
  devicesOnWorkspaceStatistics: DevicesStatistics;
  email?: Maybe<Email>;
  emails: Array<Email>;
  findApiKey?: Maybe<ApiKey>;
  me?: Maybe<User>;
  membership?: Maybe<Membership>;
  memberships: Array<Membership>;
  organization?: Maybe<Organization>;
  organizationInvitation?: Maybe<OrganizationInvitation>;
  organizationInvitations: OrganizationInvitationsPayload;
  organizationMembers?: Maybe<OrganizationMembersPayload>;
  organizationWorkspaces?: Maybe<OrganizationWorkspacesPayload>;
  organizations: Array<Organization>;
  /** Fetches the Redwood root schema. */
  redwood?: Maybe<Redwood>;
  user?: Maybe<User>;
  users: Array<User>;
  workspace?: Maybe<Workspace>;
  workspaces: Array<Workspace>;
};

/** About the Redwood queries. */
export type QueryApiKeyArgs = {
  id: Scalars["String"]["input"];
};

/** About the Redwood queries. */
export type QueryApiKeysArgs = {
  input: ApiKeysInput;
};

/** About the Redwood queries. */
export type QueryContactFormSubmissionArgs = {
  id: Scalars["String"]["input"];
};

/** About the Redwood queries. */
export type QueryDestinationArgs = {
  id: Scalars["String"]["input"];
};

/** About the Redwood queries. */
export type QueryDestinationsArgs = {
  input: DestinationsInput;
};

/** About the Redwood queries. */
export type QueryDeviceArgs = {
  id: Scalars["ID"]["input"];
};

/** About the Redwood queries. */
export type QueryDeviceCommandsArgs = {
  input: DeviceCommandsInput;
};

/** About the Redwood queries. */
export type QueryDevicesArgs = {
  input: DevicesInput;
};

/** About the Redwood queries. */
export type QueryDevicesOnWorkspaceStatisticsArgs = {
  input: DevicesOnWorkspaceStatisticsInput;
};

/** About the Redwood queries. */
export type QueryEmailArgs = {
  token: Scalars["String"]["input"];
};

/** About the Redwood queries. */
export type QueryFindApiKeyArgs = {
  key: Scalars["String"]["input"];
};

/** About the Redwood queries. */
export type QueryMembershipArgs = {
  id: Scalars["String"]["input"];
};

/** About the Redwood queries. */
export type QueryOrganizationArgs = {
  id: Scalars["String"]["input"];
};

/** About the Redwood queries. */
export type QueryOrganizationInvitationArgs = {
  id: Scalars["String"]["input"];
};

/** About the Redwood queries. */
export type QueryOrganizationInvitationsArgs = {
  input: OrganizationInvitationsInput;
};

/** About the Redwood queries. */
export type QueryOrganizationMembersArgs = {
  input: OrganizationMembersInput;
};

/** About the Redwood queries. */
export type QueryOrganizationWorkspacesArgs = {
  input: OrganizationWorkspacesInput;
};

/** About the Redwood queries. */
export type QueryOrganizationsArgs = {
  domain?: InputMaybe<Scalars["String"]["input"]>;
};

/** About the Redwood queries. */
export type QueryUserArgs = {
  id: Scalars["String"]["input"];
};

/** About the Redwood queries. */
export type QueryWorkspaceArgs = {
  id: Scalars["String"]["input"];
};

/** About the Redwood queries. */
export type QueryWorkspacesArgs = {
  orgId?: InputMaybe<Scalars["String"]["input"]>;
};

/**
 * The RedwoodJS Root Schema
 *
 * Defines details about RedwoodJS such as the current user and version information.
 */
export type Redwood = {
  __typename?: "Redwood";
  /** The current user. */
  currentUser?: Maybe<Scalars["JSON"]["output"]>;
  /** The version of Prisma. */
  prismaVersion?: Maybe<Scalars["String"]["output"]>;
  /** The version of Redwood. */
  version?: Maybe<Scalars["String"]["output"]>;
};

export type RenameOrganizationWorkspaceInput = {
  id: Scalars["ID"]["input"];
  name: Scalars["String"]["input"];
};

export type RequestInstallerAccessInput = {
  credentials: Scalars["JSON"]["input"];
  manufacturer: Scalars["String"]["input"];
  userEmail: Scalars["String"]["input"];
  workspaceId: Scalars["String"]["input"];
};

export type RequestInstallerAccessResponse = {
  __typename?: "RequestInstallerAccessResponse";
  reason?: Maybe<Scalars["String"]["output"]>;
  success: Scalars["Boolean"]["output"];
};

export type RequestManufacturerListingInput = {
  manufacturer: Scalars["String"]["input"];
  userEmail: Scalars["String"]["input"];
};

export type RequestManufacturerListingResponse = {
  __typename?: "RequestManufacturerListingResponse";
  reason?: Maybe<Scalars["String"]["output"]>;
  success: Scalars["Boolean"]["output"];
};

export type RevokeOrganizationInvitationInput = {
  id: Scalars["ID"]["input"];
};

export enum Role {
  Admin = "ADMIN",
  User = "USER",
}

export type SearchInput = {
  fields?: InputMaybe<Array<Scalars["String"]["input"]>>;
  query?: InputMaybe<Scalars["String"]["input"]>;
};

export type UpdateApiKeyInput = {
  name?: InputMaybe<Scalars["String"]["input"]>;
  revoked?: InputMaybe<Scalars["Boolean"]["input"]>;
  workspaceId: Scalars["String"]["input"];
};

export type UpdateContactFormSubmissionInput = {
  email?: InputMaybe<Scalars["String"]["input"]>;
  message?: InputMaybe<Scalars["String"]["input"]>;
  name?: InputMaybe<Scalars["String"]["input"]>;
};

export type UpdateDestinationInput = {
  destinationType?: InputMaybe<Scalars["String"]["input"]>;
  deviceType?: InputMaybe<Scalars["String"]["input"]>;
  email?: InputMaybe<Scalars["String"]["input"]>;
  headers?: InputMaybe<Scalars["JSON"]["input"]>;
  id: Scalars["ID"]["input"];
  includeWeather?: InputMaybe<Scalars["Boolean"]["input"]>;
  label?: InputMaybe<Scalars["String"]["input"]>;
  phoneNumber?: InputMaybe<Scalars["String"]["input"]>;
  secret?: InputMaybe<Scalars["String"]["input"]>;
  url?: InputMaybe<Scalars["String"]["input"]>;
  workspaceId: Scalars["String"]["input"];
};

export type UpdateEmailInput = {
  email: Scalars["String"]["input"];
  id: Scalars["String"]["input"];
};

export type UpdateMembershipInput = {
  id: Scalars["String"]["input"];
  role?: InputMaybe<Role>;
};

export type UpdateOrganizationInput = {
  domain?: InputMaybe<Scalars["String"]["input"]>;
  logo?: InputMaybe<Scalars["String"]["input"]>;
  name?: InputMaybe<Scalars["String"]["input"]>;
};

export type UpdateOrganizationInvitationInput = {
  email?: InputMaybe<Scalars["String"]["input"]>;
  organizationId?: InputMaybe<Scalars["String"]["input"]>;
  role?: InputMaybe<Role>;
  sentAt?: InputMaybe<Scalars["DateTime"]["input"]>;
  status?: InputMaybe<OrganizationInvitationStatus>;
  userId?: InputMaybe<Scalars["String"]["input"]>;
};

export type UpdateUserInput = {
  emails?: InputMaybe<Array<Scalars["String"]["input"]>>;
  firstName?: InputMaybe<Scalars["String"]["input"]>;
  lastName?: InputMaybe<Scalars["String"]["input"]>;
  profilePhoto?: InputMaybe<Scalars["String"]["input"]>;
  supertokensId?: InputMaybe<Scalars["String"]["input"]>;
};

export type UpdateWorkspaceInput = {
  name?: InputMaybe<Scalars["String"]["input"]>;
  organizationId?: InputMaybe<Scalars["String"]["input"]>;
};

export type User = {
  __typename?: "User";
  areEmailsVerified: Scalars["Boolean"]["output"];
  createdAt: Scalars["DateTime"]["output"];
  email?: Maybe<Email>;
  emails: Array<Maybe<Email>>;
  firstName?: Maybe<Scalars["String"]["output"]>;
  fullName?: Maybe<Scalars["String"]["output"]>;
  id: Scalars["String"]["output"];
  isTextureEmployee: Scalars["Boolean"]["output"];
  lastName?: Maybe<Scalars["String"]["output"]>;
  memberships: Array<Maybe<Membership>>;
  profilePhoto?: Maybe<Scalars["String"]["output"]>;
  supertokensId: Scalars["String"]["output"];
  updatedAt: Scalars["DateTime"]["output"];
};

export type Workspace = {
  __typename?: "Workspace";
  createdAt: Scalars["DateTime"]["output"];
  id: Scalars["String"]["output"];
  name: Scalars["String"]["output"];
  organization: Organization;
  organizationId: Scalars["String"]["output"];
  updatedAt: Scalars["DateTime"]["output"];
};

export type ConnectLinkFragment = {
  __typename?: "ConnectLink";
  linkToken: string;
  linkUrl: string;
};

export type DeviceOnWorkspaceDetailsFragment = {
  __typename?: "DeviceOnWorkspace";
  id: string;
  type: string;
  year?: number | null;
  name: string;
  state?: any | null;
  workspaceId: string;
  referenceId: string;
  tags?: Array<string> | null;
  connected: boolean;
  connectedAt?: any | null;
  disconnectedAt?: any | null;
  availableCommands: Array<any>;
  createdAt: any;
  updatedAt?: any | null;
  manufacturer: {
    __typename?: "DeviceManufacturer";
    name: string;
    slug: string;
    userId: string;
    deviceId: string;
    serialNumber?: string | null;
    image?: string | null;
  };
  location?: {
    __typename?: "DeviceLocation";
    fullAddress?: string | null;
    latitude?: number | null;
    longitude?: number | null;
  } | null;
  model: {
    __typename?: "DeviceModel";
    slug: string;
    name: string;
    image?: string | null;
  };
};


export type ListPayloadMetaWithFiltersObjectFragment = {
  __typename?: "ListPayloadMetaWithFiltersObject";
  count: number;
  totalCount: number;
  totalPages: number;
  hasPreviousPage: boolean;
  hasNextPage: boolean;
  filters?: {
    __typename?: "ListPayloadMetaFilters";
    default?: Array<any | null> | null;
    current?: Array<any | null> | null;
  } | null;
  pagination?: {
    __typename?: "ListPayloadMetaPagination";
    cursor?: {
      __typename?: "CursorPaginationInfo";
      endCursor?: string | null;
      startCursor?: string | null;
    } | null;
    offset?: {
      __typename?: "OffsetPaginationInfo";
      page?: number | null;
      perPage?: number | null;
    } | null;
  } | null;
};

export type CreateConnectLinkMutationVariables = Exact<{
  input: CreateConnectLinkInput;
}>;

export type CreateConnectLinkMutation = {
  __typename?: "Mutation";
  createConnectLink: {
    __typename?: "ConnectLink";
    linkToken: string;
    linkUrl: string;
  };
};

export type RequestInstallerAccessMutationVariables = Exact<{
  input: RequestInstallerAccessInput;
}>;

export type RequestInstallerAccessMutation = {
  __typename?: "Mutation";
  requestInstallerAccess: {
    __typename?: "RequestInstallerAccessResponse";
    success: boolean;
    reason?: string | null;
  };
};

export type RequestManufacturerListingMutationVariables = Exact<{
  input: RequestManufacturerListingInput;
}>;

export type RequestManufacturerListingMutation = {
  __typename?: "Mutation";
  requestManufacturerListing: {
    __typename?: "RequestManufacturerListingResponse";
    success: boolean;
    reason?: string | null;
  };
};

export type DeviceQueryVariables = Exact<{
  id: Scalars["ID"]["input"];
}>;

export type DeviceQuery = {
  __typename?: "Query";
  device?: {
    __typename?: "DeviceOnWorkspace";
    id: string;
    type: string;
    year?: number | null;
    name: string;
    state?: any | null;
    workspaceId: string;
    referenceId: string;
    tags?: Array<string> | null;
    connected: boolean;
    connectedAt?: any | null;
    disconnectedAt?: any | null;
    availableCommands: Array<any>;
    createdAt: any;
    updatedAt?: any | null;
    manufacturer: {
      __typename?: "DeviceManufacturer";
      name: string;
      slug: string;
      userId: string;
      deviceId: string;
      serialNumber?: string | null;
      image?: string | null;
    };
    location?: {
      __typename?: "DeviceLocation";
      fullAddress?: string | null;
      latitude?: number | null;
      longitude?: number | null;
    } | null;
    model: {
      __typename?: "DeviceModel";
      slug: string;
      name: string;
      image?: string | null;
    };
  } | null;
};

export type DevicesQueryVariables = Exact<{
  input: DevicesInput;
}>;

export type DevicesQuery = {
  __typename?: "Query";
  devices: {
    __typename?: "DevicesPayload";
    data?: Array<{
      __typename?: "DeviceOnWorkspace";
      id: string;
      type: string;
      year?: number | null;
      name: string;
      state?: any | null;
      workspaceId: string;
      referenceId: string;
      tags?: Array<string> | null;
      connected: boolean;
      connectedAt?: any | null;
      disconnectedAt?: any | null;
      availableCommands: Array<any>;
      createdAt: any;
      updatedAt?: any | null;
      manufacturer: {
        __typename?: "DeviceManufacturer";
        name: string;
        slug: string;
        userId: string;
        deviceId: string;
        serialNumber?: string | null;
        image?: string | null;
      };
      location?: {
        __typename?: "DeviceLocation";
        fullAddress?: string | null;
        latitude?: number | null;
        longitude?: number | null;
      } | null;
      model: {
        __typename?: "DeviceModel";
        slug: string;
        name: string;
        image?: string | null;
      };
    }> | null;
    meta?: {
      __typename?: "ListPayloadMetaWithFiltersObject";
      count: number;
      totalCount: number;
      totalPages: number;
      hasPreviousPage: boolean;
      hasNextPage: boolean;
      filters?: {
        __typename?: "ListPayloadMetaFilters";
        default?: Array<any | null> | null;
        current?: Array<any | null> | null;
      } | null;
      pagination?: {
        __typename?: "ListPayloadMetaPagination";
        cursor?: {
          __typename?: "CursorPaginationInfo";
          endCursor?: string | null;
          startCursor?: string | null;
        } | null;
        offset?: {
          __typename?: "OffsetPaginationInfo";
          page?: number | null;
          perPage?: number | null;
        } | null;
      } | null;
    } | null;
  };
};

export type FetchAllOrgsQueryVariables = Exact<{ [key: string]: never }>;

export type FetchAllOrgsQuery = {
  __typename?: "Query";
  organizations: Array<{
    __typename?: "Organization";
    id: string;
    name: string;
    domain?: string | null;
    workspaces: Array<{
      __typename?: "Workspace";
      id: string;
      name: string;
    } | null>;
  }>;
};

export type FetchAllUsersQueryVariables = Exact<{ [key: string]: never }>;

export type FetchAllUsersQuery = {
  __typename?: "Query";
  users: Array<{
    __typename?: "User";
    id: string;
    emails: Array<{ __typename?: "Email"; email: string } | null>;
    memberships: Array<{
      __typename?: "Membership";
      organization: { __typename?: "Organization"; id: string; name: string };
    } | null>;
  }>;
};

export const ConnectLinkFragmentDoc = gql`
  fragment ConnectLink on ConnectLink {
    linkToken
    linkUrl
  }
`;
export const DeviceOnWorkspaceDetailsFragmentDoc = gql`
  fragment DeviceOnWorkspaceDetails on DeviceOnWorkspace {
    id
    manufacturer {
      name
      slug
      userId
      deviceId
      serialNumber
      image
    }
    location {
      fullAddress
      latitude
      longitude
    }
    type
    model {
      slug
      name
      image
    }
    year
    name
    state
    referenceId
    tags
    connected
    connectedAt
    disconnectedAt
    availableCommands
    createdAt
    updatedAt
  }
`;

export const ListPayloadMetaWithFiltersObjectFragmentDoc = gql`
  fragment ListPayloadMetaWithFiltersObject on ListPayloadMetaWithFiltersObject {
    count
    totalCount
    totalPages
    hasPreviousPage
    hasNextPage
    filters {
      default
      current
    }
    pagination {
      cursor {
        endCursor
        startCursor
      }
      offset {
        page
        perPage
      }
    }
  }
`;
export const CreateConnectLinkDocument = gql`
  mutation CreateConnectLink($input: CreateConnectLinkInput!) {
    createConnectLink(input: $input) {
      ...ConnectLink
    }
  }
  ${ConnectLinkFragmentDoc}
`;
export type CreateConnectLinkMutationFn = Apollo.MutationFunction<
  CreateConnectLinkMutation,
  CreateConnectLinkMutationVariables
>;

/**
 * __useCreateConnectLinkMutation__
 *
 * To run a mutation, you first call `useCreateConnectLinkMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateConnectLinkMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createConnectLinkMutation, { data, loading, error }] = useCreateConnectLinkMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useCreateConnectLinkMutation(
  baseOptions?: Apollo.MutationHookOptions<
    CreateConnectLinkMutation,
    CreateConnectLinkMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    CreateConnectLinkMutation,
    CreateConnectLinkMutationVariables
  >(CreateConnectLinkDocument, options);
}
export type CreateConnectLinkMutationHookResult = ReturnType<
  typeof useCreateConnectLinkMutation
>;
export type CreateConnectLinkMutationResult =
  Apollo.MutationResult<CreateConnectLinkMutation>;
export type CreateConnectLinkMutationOptions = Apollo.BaseMutationOptions<
  CreateConnectLinkMutation,
  CreateConnectLinkMutationVariables
>;
export const RequestInstallerAccessDocument = gql`
  mutation RequestInstallerAccess($input: RequestInstallerAccessInput!) {
    requestInstallerAccess(input: $input) {
      success
      reason
    }
  }
`;
export type RequestInstallerAccessMutationFn = Apollo.MutationFunction<
  RequestInstallerAccessMutation,
  RequestInstallerAccessMutationVariables
>;

/**
 * __useRequestInstallerAccessMutation__
 *
 * To run a mutation, you first call `useRequestInstallerAccessMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useRequestInstallerAccessMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [requestInstallerAccessMutation, { data, loading, error }] = useRequestInstallerAccessMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useRequestInstallerAccessMutation(
  baseOptions?: Apollo.MutationHookOptions<
    RequestInstallerAccessMutation,
    RequestInstallerAccessMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    RequestInstallerAccessMutation,
    RequestInstallerAccessMutationVariables
  >(RequestInstallerAccessDocument, options);
}
export type RequestInstallerAccessMutationHookResult = ReturnType<
  typeof useRequestInstallerAccessMutation
>;
export type RequestInstallerAccessMutationResult =
  Apollo.MutationResult<RequestInstallerAccessMutation>;
export type RequestInstallerAccessMutationOptions = Apollo.BaseMutationOptions<
  RequestInstallerAccessMutation,
  RequestInstallerAccessMutationVariables
>;
export const RequestManufacturerListingDocument = gql`
  mutation RequestManufacturerListing(
    $input: RequestManufacturerListingInput!
  ) {
    requestManufacturerListing(input: $input) {
      success
      reason
    }
  }
`;
export type RequestManufacturerListingMutationFn = Apollo.MutationFunction<
  RequestManufacturerListingMutation,
  RequestManufacturerListingMutationVariables
>;

/**
 * __useRequestManufacturerListingMutation__
 *
 * To run a mutation, you first call `useRequestManufacturerListingMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useRequestManufacturerListingMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [requestManufacturerListingMutation, { data, loading, error }] = useRequestManufacturerListingMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useRequestManufacturerListingMutation(
  baseOptions?: Apollo.MutationHookOptions<
    RequestManufacturerListingMutation,
    RequestManufacturerListingMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    RequestManufacturerListingMutation,
    RequestManufacturerListingMutationVariables
  >(RequestManufacturerListingDocument, options);
}
export type RequestManufacturerListingMutationHookResult = ReturnType<
  typeof useRequestManufacturerListingMutation
>;
export type RequestManufacturerListingMutationResult =
  Apollo.MutationResult<RequestManufacturerListingMutation>;
export type RequestManufacturerListingMutationOptions =
  Apollo.BaseMutationOptions<
    RequestManufacturerListingMutation,
    RequestManufacturerListingMutationVariables
  >;
export const DeviceDocument = gql`
  query Device($id: ID!) {
    device(id: $id) {
      ...DeviceOnWorkspaceDetails
    }
  }
  ${DeviceOnWorkspaceDetailsFragmentDoc}
`;

/**
 * __useDeviceQuery__
 *
 * To run a query within a React component, call `useDeviceQuery` and pass it any options that fit your needs.
 * When your component renders, `useDeviceQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useDeviceQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useDeviceQuery(
  baseOptions: Apollo.QueryHookOptions<DeviceQuery, DeviceQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<DeviceQuery, DeviceQueryVariables>(
    DeviceDocument,
    options,
  );
}
export function useDeviceLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<DeviceQuery, DeviceQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<DeviceQuery, DeviceQueryVariables>(
    DeviceDocument,
    options,
  );
}
export type DeviceQueryHookResult = ReturnType<typeof useDeviceQuery>;
export type DeviceLazyQueryHookResult = ReturnType<typeof useDeviceLazyQuery>;
export type DeviceQueryResult = Apollo.QueryResult<
  DeviceQuery,
  DeviceQueryVariables
>;
export const DevicesDocument = gql`
  query Devices($input: DevicesInput!) {
    devices(input: $input) {
      data {
        ...DeviceOnWorkspaceDetails
      }
      meta {
        ...ListPayloadMetaWithFiltersObject
      }
    }
  }
  ${DeviceOnWorkspaceDetailsFragmentDoc}
  ${ListPayloadMetaWithFiltersObjectFragmentDoc}
`;

/**
 * __useDevicesQuery__
 *
 * To run a query within a React component, call `useDevicesQuery` and pass it any options that fit your needs.
 * When your component renders, `useDevicesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useDevicesQuery({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useDevicesQuery(
  baseOptions: Apollo.QueryHookOptions<DevicesQuery, DevicesQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<DevicesQuery, DevicesQueryVariables>(
    DevicesDocument,
    options,
  );
}
export function useDevicesLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    DevicesQuery,
    DevicesQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<DevicesQuery, DevicesQueryVariables>(
    DevicesDocument,
    options,
  );
}
export type DevicesQueryHookResult = ReturnType<typeof useDevicesQuery>;
export type DevicesLazyQueryHookResult = ReturnType<typeof useDevicesLazyQuery>;
export type DevicesQueryResult = Apollo.QueryResult<
  DevicesQuery,
  DevicesQueryVariables
>;
export const FetchAllOrgsDocument = gql`
  query fetchAllOrgs {
    organizations {
      id
      name
      domain
      workspaces {
        id
        name
      }
    }
  }
`;

/**
 * __useFetchAllOrgsQuery__
 *
 * To run a query within a React component, call `useFetchAllOrgsQuery` and pass it any options that fit your needs.
 * When your component renders, `useFetchAllOrgsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useFetchAllOrgsQuery({
 *   variables: {
 *   },
 * });
 */
export function useFetchAllOrgsQuery(
  baseOptions?: Apollo.QueryHookOptions<
    FetchAllOrgsQuery,
    FetchAllOrgsQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<FetchAllOrgsQuery, FetchAllOrgsQueryVariables>(
    FetchAllOrgsDocument,
    options,
  );
}
export function useFetchAllOrgsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    FetchAllOrgsQuery,
    FetchAllOrgsQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<FetchAllOrgsQuery, FetchAllOrgsQueryVariables>(
    FetchAllOrgsDocument,
    options,
  );
}
export type FetchAllOrgsQueryHookResult = ReturnType<
  typeof useFetchAllOrgsQuery
>;
export type FetchAllOrgsLazyQueryHookResult = ReturnType<
  typeof useFetchAllOrgsLazyQuery
>;
export type FetchAllOrgsQueryResult = Apollo.QueryResult<
  FetchAllOrgsQuery,
  FetchAllOrgsQueryVariables
>;
export const FetchAllUsersDocument = gql`
  query fetchAllUsers {
    users {
      id
      emails {
        email
      }
      memberships {
        organization {
          id
          name
        }
      }
    }
  }
`;

/**
 * __useFetchAllUsersQuery__
 *
 * To run a query within a React component, call `useFetchAllUsersQuery` and pass it any options that fit your needs.
 * When your component renders, `useFetchAllUsersQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useFetchAllUsersQuery({
 *   variables: {
 *   },
 * });
 */
export function useFetchAllUsersQuery(
  baseOptions?: Apollo.QueryHookOptions<
    FetchAllUsersQuery,
    FetchAllUsersQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<FetchAllUsersQuery, FetchAllUsersQueryVariables>(
    FetchAllUsersDocument,
    options,
  );
}
export function useFetchAllUsersLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    FetchAllUsersQuery,
    FetchAllUsersQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<FetchAllUsersQuery, FetchAllUsersQueryVariables>(
    FetchAllUsersDocument,
    options,
  );
}
export type FetchAllUsersQueryHookResult = ReturnType<
  typeof useFetchAllUsersQuery
>;
export type FetchAllUsersLazyQueryHookResult = ReturnType<
  typeof useFetchAllUsersLazyQuery
>;
export type FetchAllUsersQueryResult = Apollo.QueryResult<
  FetchAllUsersQuery,
  FetchAllUsersQueryVariables
>;
