"use client";

import React from "react";
import { useRouter, usePathname, useSearchParams } from "next/navigation";
import { ROUTES } from "@/routes";

import { findClosestRoute } from "@/utils/routes";

import useSessionStorage, {
  PREVIOUS_URL_STORAGE_KEY,
} from "@/hooks/useSessionStorage";

export function useNavigationService() {
  const router = useRouter();
  const pathname = usePathname();
  const params = useSearchParams();

  const { getItem } = useSessionStorage();

  /**
   * This function is used to preserve search params when navigating back
   * It's used by the BackButton component, but can be used elsewhere
   */
  function backTo(path?: string) {
    if (!path) {
      router.back();
      return;
    }
    const previousUrl = getItem(PREVIOUS_URL_STORAGE_KEY);
    if (!previousUrl) {
      navigateTo(path);
      return;
    }
    if (previousUrl.includes(path)) {
      navigateTo(previousUrl);
    } else {
      navigateTo(path);
    }
  }

  function navigateTo(path: string) {
    if (!path) return;
    try {
      if (path.startsWith("http")) {
        window.open(path, "_blank");
      } else {
        router.push(path);
      }
    } catch (error) {
      console.error(`Error navigating to ${path}`, error);
    }
  }

  function navigateToHomePage() {
    router.push(ROUTES.private.home);
  }

  function navigateToPendingInvitations() {
    router.push(ROUTES.private.pendingInvitations);
  }

  function navigateToSignIn(skipRedirectTo?: boolean) {
    if (skipRedirectTo) {
      router.push(ROUTES.public.signIn);
      return;
    }
    const redirectTo = params.get("redirectTo");
    const invitationId = params.get("invitation");
    let path = "";
    if (!redirectTo && !invitationId) {
      if (
        !isPublicRoute(window.location.pathname) &&
        !isVerifyEmailRoute(window.location.pathname)
      ) {
        const previousPath = window.location.pathname + window.location.search;
        if (previousPath && previousPath !== "/") {
          path += "?redirectTo=" + encodeURIComponent(previousPath);
        }
      }
    } else {
      const parts = [];
      if (redirectTo) parts.push(`redirectTo=${redirectTo}`);
      if (invitationId) parts.push(`invitation=${invitationId}`);
      path += "?" + parts.join("&");
    }
    router.push(ROUTES.public.signIn + path);
  }

  /**
   * There is no sense in having redirection in signUp flow
   * However, the users tend to click back & forth between sign-in and sign-up so we need to handle that
   */
  function navigateToSignUp(skipRedirectTo?: boolean) {
    if (skipRedirectTo) {
      router.push(ROUTES.public.signUp);
      return;
    }
    const redirectTo = params.get("redirectTo");
    const invitationId = params.get("invitation");
    let path = "";
    if (!redirectTo && !invitationId) {
      if (
        !isPublicRoute(window.location.pathname) &&
        !isVerifyEmailRoute(window.location.pathname)
      ) {
        const previousPath = window.location.pathname + window.location.search;
        if (previousPath && previousPath !== "/") {
          path += "?redirectTo=" + encodeURIComponent(previousPath);
        }
      }
    } else {
      const parts = [];
      if (redirectTo) parts.push(`redirectTo=${redirectTo}`);
      if (invitationId) parts.push(`invitation=${invitationId}`);
      path += "?" + parts.join("&");
    }
    router.push(ROUTES.public.signUp + path);
  }

  function navigateToVerifyEmail() {
    router.push(ROUTES.private.verifyEmail);
  }

  function navigateToResetPassword() {
    router.push(ROUTES.public.resetPassword);
  }

  function navigateToOrganizationOnboarding() {
    router.push(ROUTES.private.onboardingOrganization);
  }

  function navigateToSources() {
    router.push(ROUTES.private.sources);
  }

  function navigateToApps() {
    router.push(ROUTES.private.apps);
  }

  function navigateToCustomers() {
    router.push(ROUTES.private.customers);
  }

  function navigateToSettings() {
    router.push(ROUTES.private.settings);
  }

  function navigateToSourcesInstallerAccess() {
    router.push(ROUTES.private.installerAccess);
  }

  function navigateToSourcesUtilityData() {
    router.push(ROUTES.private.utilityData);
  }

  function navigateToDeviceList() {
    router.push(ROUTES.private.devices);
  }

  function isPublicRoute(pathnameToCheck: string = pathname) {
    const publicRoutes = Object.values(ROUTES.public);
    return publicRoutes.some((route) => pathnameToCheck.includes(route));
  }

  function isSigningRoute(pathnameToCheck: string = pathname) {
    return (
      pathnameToCheck.includes(ROUTES.public.signIn) ||
      pathnameToCheck.includes(ROUTES.public.signUp) ||
      pathnameToCheck.includes(ROUTES.public.resetPassword)
    );
  }

  function isVerifyEmailRoute(pathnameToCheck: string = pathname) {
    return pathnameToCheck.includes(ROUTES.private.verifyEmail);
  }

  /**
   * Checks if the current route is under an [id] folder in the Next.js structure
   */
  function isRouteUnderIdFolder(pathnameToCheck: string = pathname) {
    const closestRoute = findClosestRoute(pathnameToCheck);
    // TODO: don't want to spend too much time on this now, but this is not a robust solution
    const routesWithIds = [
      "ROUTES.private.site",
      "ROUTES.private.customer",
      "ROUTES.private.device",
    ];
    if (routesWithIds.includes(closestRoute)) {
      return true;
    }
  }

  /**
   * Primary route is the first level route, IE routes that appear in the main menu
   */
  function getPrimaryRoute() {
    return "/" + pathname.split("/")[1];
  }

  function canGoBack(): boolean {
    return window.history.length > 1;
  }

  return {
    backTo,
    navigateTo,
    navigateToOrganizationOnboarding,
    navigateToHomePage,
    navigateToSourcesInstallerAccess,
    navigateToSourcesUtilityData,
    navigateToSources,
    navigateToApps,
    navigateToCustomers,
    navigateToPendingInvitations,
    navigateToSignIn,
    navigateToSignUp,
    navigateToResetPassword,
    navigateToVerifyEmail,
    navigateToSettings,
    navigateToDeviceList,
    isSigningRoute,
    isPublicRoute,
    isVerifyEmailRoute,
    isRouteUnderIdFolder,
    getPrimaryRoute,
    canGoBack,
  };
}
